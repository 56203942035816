import React from 'react';
import Layout from 'src/components/Layout';
import { Section } from '../components/Section';
import { TextContainer, SectionTitle, TitleDescription } from 'src/components/Typography';
import sizes from 'src/style/sizes';
import { TopbarStaticSpace } from '../components/Topbar';
import { FAQWhiteBG } from '../components/QuestionsAnswer/index';
import PrivacyPolicy from 'src/data/PrivacyPolicy';

const CGUpage = React.memo(({ location }) => {
  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C|B2B"
      pageName="Mentions légales"
      pageType="page"
      metaTitle="Politique de confidentialité | Le Reacteur"
    >
      <Section theme="primary" css={{ height: sizes.purpleBgHeightDesktop }} />
      <TopbarStaticSpace />
      <Section theme="secondary">
        <FAQWhiteBG marginTop={sizes.marginTopContainer} theme="light">
          <SectionTitle css={{ textAlign: 'center', margin: '50px 0' }}>
            Politique de confidentialité
          </SectionTitle>
          {PrivacyPolicy.map((item) => {
            return (
              <div
                key={item.title}
                css={{
                  padding: '20px 0px 20px 0px',
                  '&>p>ul>li': {
                    marginLeft: 20,
                  },
                  '&>p>p>strong': {
                    marginBottom: 5,
                  },
                }}
              >
                <TitleDescription
                  dangerouslySetInnerHTML={{ __html: item.title }}
                  css={{ color: '#1B2733' }}
                />
                <TextContainer
                  dangerouslySetInnerHTML={{ __html: item.text }}
                  css={{
                    fontSize: 17,
                    lineHeight: '26px',
                    color: '#546476',
                  }}
                />
              </div>
            );
          })}
        </FAQWhiteBG>
      </Section>
    </Layout>
  );
});

export default CGUpage;
